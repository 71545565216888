import { messages as portugueseMessages } from "./pt";
// import { messages as englishMessages } from "./en";
// import { messages as spanishMessages } from "./es";
// import { messages as trMessages } from "./tr";

const messages = {
	...portugueseMessages,
	// ...englishMessages,
	// ...spanishMessages,
	// ...trMessages
};

export { messages };
